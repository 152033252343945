import BookFreeClassButton from '@components/common/book-free-class-btn'
import MsgTrans from '@components/common/i18n/message-trans'
import { FastlyImage } from '@components/common/image'
import Button from '@components/atoms/button'
import { LinguiJSMessageDescriptor } from '@lib/types/common'
import { Trans, defineMessage } from '@lingui/macro'
import { Check, MapPin } from 'react-feather'
import { coursePageResponsive, responsive } from '@common/constants'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'

type Props = {
  shouldScrollToTop?: boolean
  urlQuery?: string
  isCoursepage?: boolean
}

type TrustPilotTestimonialType = {
  name: LinguiJSMessageDescriptor
  description: JSX.Element
  userType: LinguiJSMessageDescriptor
  link: string
  countryCode: string
}

export const TRUSTPILOT_TESTIMONIALS: TrustPilotTestimonialType[] = [
  {
    name: defineMessage({ message: 'Adeyemo O' }),
    userType: defineMessage({ message: 'Codingal Parent' }),
    description: (
      <Trans id="homepage.trustpilotReview.testimonial1">
        Part of my experience is that, the Coding class is very good, and one of
        the correct path to success for anyone. Also, my experience with one of
        the teacher’s, Ms. Shana. Shana is a great teacher. She teaches with
        passion. Shana teaches with students’ centered interest. <br /> Shana
        demonstrates a good knowledge of her job as a teacher in coding.
      </Trans>
    ),
    link: 'https://www.trustpilot.com/users/65fa2a408b385b0012541433',
    countryCode: 'US',
  },
  {
    name: defineMessage({ message: `Sabeen` }),
    userType: defineMessage({ message: 'Codingal Parent' }),
    description: (
      <Trans id="homepage.trustpilotReview.testimonial2">
        My son took python classes from Amit Dhanwani. He was a very supportive
        and friendly tutor. My son always looked forward to his lessons. He went
        above and beyond to assist him and make him feel comfortable.
      </Trans>
    ),
    link: 'https://www.trustpilot.com/reviews/654cdd52c144e0e7a4009803',
    countryCode: 'GB',
  },
  {
    name: defineMessage({ message: 'Aarav Hajare' }),
    userType: defineMessage({ message: 'Codingal Student' }),
    description: (
      <Trans id="homepage.trustpilotReview.testimonial3">
        Great Nice Good I hope this letter finds you well. I am writing to
        express my heartfelt gratitude for the exceptional guidance and support
        you have provided me throughout my coding journey. Your dedication and
        passion for teaching have truly made a significant impact on my learning
        experience ....
      </Trans>
    ),
    link: 'https://www.trustpilot.com/reviews/665dd52eac31c51c7fc7576d',
    countryCode: 'IN',
  },
  {
    name: defineMessage({ message: `Bob Howard` }),
    userType: defineMessage({ message: 'Codingal Parent' }),
    description: (
      <Trans id="homepage.trustpilotReview.testimonial4">
        My son was 6 years old when he started programming in blocks about 3
        years ago. <br /> At 9 years old he can now program by writing code in
        html and now he is 5 months into python. <br /> The python course became
        too advanced for his age, so the team at codingal recognized their
        curriculum became ....
      </Trans>
    ),
    link: 'https://www.trustpilot.com/reviews/655cde8aa2e2f6a0f5a50159',
    countryCode: 'AU',
  },
  {
    name: defineMessage({ message: `Shariq Majeed` }),
    userType: defineMessage({ message: 'Codingal Parent' }),
    description: (
      <Trans id="homepage.trustpilotReview.testimonial5">
        My son is currently learning with codingal, I must say I am truly
        satisfied with the teachers performance the attention they give and the
        level of mentorship is amazing. <br />
        Teachers are very friendly ....
      </Trans>
    ),
    link: 'https://www.trustpilot.com/reviews/653a54ab88d0a5fa0d35e76a',
    countryCode: 'US',
  },
  {
    name: defineMessage({ message: 'Lutfun Nahar' }),
    userType: defineMessage({ message: 'Codingal Parent' }),
    description: (
      <Trans id="homepage.trustpilotReview.testimonial6">
        I am thrilled to share my positive experience with Codingal, where my
        son has been a student for several months now. As a parent, it is
        incredibly satisfying to witness my child’s growth in a field as
        critical as coding, and Codingal has played a pivotal role in this
        journey....
      </Trans>
    ),
    link: 'https://www.trustpilot.com/reviews/666db4665cf468ae29c62722',
    countryCode: 'BD',
  },
  {
    name: defineMessage({ message: 'Belinda Forster' }),
    userType: defineMessage({ message: 'Codingal Parent' }),
    description: (
      <Trans id="homepage.trustpilotReview.testimonial7">
        Our teacher has managed to keep our three children engaged and focused
        for an hour - online! Classroom management has been amazing. Our
        children are exciting to start the lesson and they stay focused. Even
        the one child who struggles to focus and thought that computers were
        just for games - she managed to capture his attention....
      </Trans>
    ),
    link: 'https://www.trustpilot.com/reviews/65c4e1eb9d8da73c46e42166',
    countryCode: 'ZM',
  },
  {
    name: defineMessage({ message: 'Michael Gamedoagbao' }),
    userType: defineMessage({ message: 'Codingal Parent' }),
    description: (
      <Trans id="homepage.trustpilotReview.testimonial8">
        Codingal is so far the best Educational platform for learning STEM. Very
        amazing!! Very dedicated teacher who make sure my child understand every
        topic. My child who knew nothing about AI can now bost in Designing her
        own gaming App. Soon she will be expert in computer programming. <br />
        Thank you Codingal. <br />
        5***** is even too small.
      </Trans>
    ),
    link: 'https://www.trustpilot.com/reviews/654fa66d6caa24fe0ab7fde2',
    countryCode: 'GH',
  },
  {
    name: defineMessage({ message: `Picoty Ng'eno` }),
    userType: defineMessage({ message: 'Codingal Parent' }),
    description: (
      <Trans id="homepage.trustpilotReview.testimonial9">
        Ruth’s Teacher, Ishita, is so connected to my child and I as the parent.
        I appreciate her persistent follow-up that saw us even within holidays
        able to attend all classes. Ruth always looks forward to joining her
        class. Ishita ensures that Ruth gets the best out of each lessons. This
        gives me confidence that my daughter is actually learning.
      </Trans>
    ),
    link: 'https://www.trustpilot.com/reviews/6596c1c60bb5f9506be8cad4',
    countryCode: 'KE',
  },
  {
    name: defineMessage({ message: `Mashuk Ahmed` }),
    userType: defineMessage({ message: 'Codingal Parent' }),
    description: (
      <Trans id="homepage.trustpilotReview.testimonial10">
        Codingal is an excellent platform for learning coding. My 11 year old
        son is learning coding here from last two years. He learnt a diverse
        array of languages from HTML and CSS to Python, Data Science, Flask, and
        SQL, my son has developed a solid foundation. Codingal’s structured
        approach, interactive lessons, quizzes, and ....
      </Trans>
    ),
    link: 'https://www.trustpilot.com/reviews/6548e6003f49c7506871fa6d',
    countryCode: 'IN',
  },
  {
    name: defineMessage({ message: `Maria` }),
    userType: defineMessage({ message: 'Codingal Parent' }),
    description: (
      <Trans id="homepage.trustpilotReview.testimonial12">
        This platform is amazing for kids to learn coding in such young age. The
        staff is so cooperative and the instructor knows how to make kids learn
        in more interesting way. Codingal you are doing Amazing work!
      </Trans>
    ),
    link: 'https://www.trustpilot.com/reviews/658bdf3f9333f69deab0a32b',
    countryCode: 'PK',
  },
  {
    name: defineMessage({ message: `Ali Omar` }),
    userType: defineMessage({ message: 'Codingal Parent' }),
    description: (
      <Trans id="homepage.trustpilotReview.testimonial13">
        My son has been with Codingal for a year, and it’s been a fantastic
        experience. Codingal is a trustworthy place for learning coding, and
        their instructor, Amit Chander Dhanwani, is amazing. The curriculum is
        well-structured, and Amit’s teaching has greatly improved my son’s
        coding skills. The platform is easy to use, and ....
      </Trans>
    ),
    link: 'https://www.trustpilot.com/reviews/653a52705501653d2ea17629',
    countryCode: 'BD',
  },

  {
    name: defineMessage({ message: `Salta Zhangaliyeva` }),
    userType: defineMessage({ message: 'Codingal Parent' }),
    description: (
      <Trans id="homepage.trustpilotReview.testimonial14">
        Our experience is great! We love our teacher Prof Amit Dhanwani! He is
        so great with my daughter and make her engage in her lessons and be
        interested in class! He is really at communication, too! We are very
        happy with our lessons!
      </Trans>
    ),
    link: 'https://www.trustpilot.com/reviews/653a4819b8c39d9949653c9f',
    countryCode: 'TH',
  },
  {
    name: defineMessage({ message: `Abdullah Rashid` }),
    userType: defineMessage({ message: 'Codingal Student' }),
    description: (
      <Trans id="homepage.trustpilotReview.testimonial15">
        my teacher was really supportive throughout my journey, and this company
        has made coding something everyone is welcome to do.
      </Trans>
    ),
    link: 'https://www.trustpilot.com/reviews/653cafe654d1b981658e6030',
    countryCode: 'AE',
  },
]

const TestimonialCard = ({
  testimonial,
}: {
  testimonial: TrustPilotTestimonialType
}) => {
  return (
    <a
      href={testimonial.link}
      target="_blank"
      rel="nofollow noreferrer"
      className="shadow hover:shadow-lg transition-all duration-200 rounded-md max-w-full p-4 bg-grey-100 h-96"
      style={{ width: '290px' }}
    >
      <div className="flex items-center justify-between pb-2">
        <div className="flex items-center">
          <FastlyImage
            src="/images/coding-summer-camp/image-placeholder.png"
            webpSrc="/images/coding-summer-camp/image-placeholder.webp"
            width={42}
            height={42}
          />
          <div className="px-2">
            <p className="font-700 truncate ...">
              <MsgTrans msg={testimonial.name} />
            </p>
            <p>
              <MsgTrans msg={testimonial.userType} />
            </p>
          </div>
        </div>
        <FastlyImage
          src="/images/coding-summer-camp/trust-pilot-black.png"
          webpSrc="/images/coding-summer-camp/trust-pilot-black.webp"
          width={76}
          height={36}
        />
      </div>
      <div className="w-full border border-grey-300" />
      <p className="text-base font-700 pt-2">My Experience</p>
      <p className="pb-2" style={{ minHeight: '183px' }}>
        {testimonial.description}
      </p>
      <div className="flex justify-between py-2 items-center">
        <div className="flex items-center">
          <MapPin width={14} height={14} className="text-orange" />{' '}
          <p className="pl-1 text-grey-800">{testimonial.countryCode}</p>
        </div>
        <div className="flex items-center text-sm">
          <span
            className="rounded-full bg-orange-200"
            style={{ padding: '2px' }}
          >
            <Check
              height={13}
              width={13}
              strokeWidth={3}
              className="text-orange"
            />
          </span>
          <p className="ml-1 text-left">Verified</p>
        </div>
      </div>
    </a>
  )
}

const TrustpilotReview = ({
  shouldScrollToTop,
  urlQuery,
  isCoursepage,
}: Props) => {
  return (
    <div
      className={` ${
        isCoursepage
          ? 'mx-auto max-w-xs sm:max-w-sm lg:max-w-xl xl:max-w-3xl container'
          : 'container mx-auto'
      } flex flex-col space-y-10 leading-tight pt-12 text-grey`}
    >
      <Carousel
        responsive={isCoursepage ? coursePageResponsive : responsive}
        autoPlay={true}
        swipeable={true}
        draggable={true}
        showDots={true}
        infinite={true}
        partialVisible={true}
        dotListClass="mt-10"
        sliderClass="px-10"
        containerClass="custom-corousel-container"
      >
        {TRUSTPILOT_TESTIMONIALS.map((testimonial, index) => (
          <div className="slider flex justify-center mb-10" key={index}>
            <TestimonialCard testimonial={testimonial} />
          </div>
        ))}
      </Carousel>
      <p className="text-center text-grey text-sm mt-6">
        <Trans>
          Rated 4.7 out of 5 based on{' '}
          <a
            href="https://www.trustpilot.com/review/www.codingal.com"
            target="_blank"
            rel="nofollow noreferrer"
            className="underline"
          >
            165 reviews
          </a>{' '}
          on Trustpilot
        </Trans>
      </p>
      <div className="flex flex-col w-full mx-auto space-y-2 lg:flex-row lg:w-auto lg:space-y-0 lg:space-x-4 mt-12">
        <a href="/reviews-and-testimonials/" target="_blank" rel="noreferrer">
          <Button className="w-full" variant="outline" size="xl">
            <Trans>See more reviews</Trans>
          </Button>
        </a>
        {shouldScrollToTop && (
          <BookFreeClassButton
            size="xl"
            shouldScrollToTop={shouldScrollToTop}
            query={urlQuery}
          />
        )}
        {!shouldScrollToTop && (
          <a
            className="btn btn-orange w-full md:w-auto py-3 text-lg text-center"
            href="#courses"
          >
            Claim your spot now
          </a>
        )}
      </div>
    </div>
  )
}
export default TrustpilotReview
