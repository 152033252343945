import { coursePageResponsive, responsive } from '@common/constants'
import BookFreeClassButton from '@components/common/book-free-class-btn'
import MsgTrans from '@components/common/i18n/message-trans'
import Loader from '@components/common/loader'
import { VideoTestimonialType } from '@lib/data/summer-coding-camp'
import { useEffect, useRef, useState } from 'react'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'

type VideoTestimonialProps = {
  title: string | JSX.Element | React.ReactNode
  video_testimonial: VideoTestimonialType[]
  isSummerCampPage?: boolean
  urlQuery?: string
  displayCTA?: boolean
  isCoursepage?: boolean
}

// Extend the IframeHTMLAttributes interface
declare module 'react' {
  interface IframeHTMLAttributes<T> extends React.HTMLAttributes<T> {
    loading?: 'lazy' | 'eager' | 'auto'
  }
}

const VideoCard = ({ testimonial }: { testimonial: VideoTestimonialType }) => {
  const [isVisible, setIsVisible] = useState(false)
  const videoRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setIsVisible(true)
          observer.disconnect() // Stop observing once it's visible
        }
      },
      { threshold: 0.1 } // Trigger when 10% of the video is in view
    )

    if (videoRef.current) {
      observer.observe(videoRef.current)
    }

    return () => observer.disconnect()
  }, [])

  return (
    <div ref={videoRef} className="space-y-2 text-center px-2">
      <div className="relative" style={{ height: '340px', width: '255px' }}>
        {isVisible ? (
          <iframe
            loading="lazy"
            src={`${testimonial.video}?autoplay=true&loop=true&muted=true&preload=false&responsive=true`}
            className="border-none absolute top-0 h-full w-full"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          ></iframe>
        ) : (
          <div className="absolute top-0 h-full w-full bg-gray-300 flex items-center justify-center">
            <Loader />
          </div>
        )}
      </div>

      {testimonial.name && (
        <p className="font-600 text-2xl">
          <MsgTrans msg={testimonial.name} />
        </p>
      )}
      {testimonial.description && (
        <p className="text-sm">
          <MsgTrans msg={testimonial.description} />
        </p>
      )}
      {testimonial.type && (
        <p className="text-sm text-grey-800">
          <MsgTrans msg={testimonial.type} />
        </p>
      )}
    </div>
  )
}

const VideoTestimonial = ({
  title,
  video_testimonial,
  isSummerCampPage,
  urlQuery,
  displayCTA,
  isCoursepage,
}: VideoTestimonialProps) => {
  return (
    <div
      className={` ${
        isCoursepage
          ? 'mx-auto max-w-xs sm:max-w-sm lg:max-w-xl xl:max-w-3xl container'
          : 'container mx-auto'
      } flex flex-col space-y-10 leading-tight pt-12 text-grey`}
    >
      <h2
        className={
          isCoursepage
            ? 'text-2xl font-700 mb-6 leading-tight'
            : 'text-2xl lg:text-4xl font-600 mb-4 max-w-4xl mx-auto text-center'
        }
      >
        {title}
      </h2>

      <Carousel
        responsive={isCoursepage ? coursePageResponsive : responsive}
        autoPlay={true}
        swipeable={true}
        draggable={true}
        showDots={true}
        infinite={true}
        partialVisible={true}
        dotListClass="mt-10"
        sliderClass="px-10"
        containerClass="custom-corousel-container"
      >
        {video_testimonial.map((testimonial, index) => (
          <div className="slider flex justify-center mb-10" key={index}>
            <VideoCard testimonial={testimonial} />
          </div>
        ))}
      </Carousel>

      {isSummerCampPage && (
        <div className="flex justify-center pt-8 pb-16">
          <a
            className="btn btn-orange w-full md:w-auto py-3 text-lg"
            href="#courses"
          >
            Claim your spot now
          </a>
        </div>
      )}
      {displayCTA && (
        <div className="flex justify-center pt-8">
          <BookFreeClassButton size="xl" query={urlQuery} />
        </div>
      )}
    </div>
  )
}

export default VideoTestimonial
